<template>
  <div id="app"> <div class="content-wrapper">
    <header>
      <img src="@/assets/inklusivdigital.png" alt="InklusivDigital Logo" class="logo"/>
      <!-- Rest Ihrer Header-Komponente -->
    </header>
<div v-if="showIntroduction" class="survey-introduction">
    <h1>
	Herzlich willkommen zur Umfrage: "Digitale Medien in der inklusiven Schule"</h1>
<p>
Im Rahmen eines länderübergreifenden Infrastrukturvorhabens im Digitalpakt Schule möchten wir, eine gemeinsame Initiative der Bundesländer Schleswig-Holstein, Berlin, Hamburg, Mecklenburg-Vorpommern, Niedersachsen, Nordrhein-Westfalen, Rheinland-Pfalz, Baden-Württemberg und Bremen, kostenfrei hochwertige Fortbildungsmaterialien im Bereich der digitalen Medien für die inklusive Schule entwickeln.
</p>
<p>
<strong>Ihre Meinung zählt!</strong> Wir sind bestrebt, Materialien zu erstellen, die optimal auf die Bedürfnisse von Lehrkräften, Studierenden und Referendar:innen zugeschnitten sind. Ihre Teilnahme an dieser Umfrage ist entscheidend, um die Entwicklung unserer Bausteine zu priorisieren und sicherzustellen, dass sie Ihren Anforderungen und Erwartungen entsprechen.
</p>
<p>
Die Umfrage nimmt nur wenige Minuten in Anspruch, aber Ihr Beitrag wird einen langfristigen Einfluss auf die Qualität und Relevanz der Fortbildungsmaterialien haben. Ihre Rückmeldungen werden direkt in die Priorisierung und Gestaltung der Inhalte einfließen, um ein zielgerichtetes und wirkungsvolles Lernerlebnis zu schaffen.
</p>
<p>
Nehmen Sie jetzt teil und helfen Sie uns, die Zukunft der digitalen Bildung in der inklusiven Schule aktiv mitzugestalten!
</p>
<button @click="startSurvey" class="start-button">Los geht's!</button>
</div>	
	
   <div v-else>
   <div v-if="!showTeacherInfoForm && !lastQuestionAnswered">

    <TitleSection
      :title="currentBaustein.name"
      :description="currentBaustein.beschreibung"
    />
  <RatingButtons
  ref="ratingButtons"
  :options="ratingOptions"
  :currentBlockIndex="currentIndex"
  @rate="handleRate"
/>
    <button class="skip-button" @click="skipBlock">Frage überspringen</button>
    <ProgressBar
      :current="currentIndex + 1"
      :total="bausteine.length"
    />
	
	</div>
    <div v-if="!showTeacherInfoForm">
      <!-- Ihr bestehender Inhalt für die Bausteinbewertung -->
    </div>
    <TeacherInfoForm v-if="showTeacherInfoForm" />
  </div>
  </div>
  </div>
   <div>
   

  </div>

</template>

<script>
import TeacherInfoForm from './components/TeacherInfoForm.vue';
import TitleSection from './components/TitleSection.vue';
import RatingButtons from './components/RatingButtons.vue';
import ProgressBar from './components/ProgressBar.vue';

export default {
  components: {
    TitleSection,
    RatingButtons,
    ProgressBar,
	TeacherInfoForm
  },
  data() {
    return {
      currentIndex: 0,
userId: null,
	showIntroduction: true,
	showTeacherInfoForm: false,
	bausteine: [],
     
      ratingOptions: ['Sehr relevant', 'Relevant', 'Weniger relevant', 'Nicht relevant']
    };
  },
  async mounted() {
    await this.fetchBausteine();
  },
  computed: {
  currentBaustein() {
      return this.bausteine.length > 0 ? this.bausteine[this.currentIndex] : { name: '', beschreibung: '' };
    }
  },
  methods: {
  async sendRating(bausteinId, rating) {
      const userId = this.getUserId(); // Annahme: getUserId ist definiert
      try {
        const response = await fetch('save_rating.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ bausteinId, rating, userId })
        });
        const result = await response.json();
        console.log(result); // Feedback verarbeiten
      } catch (error) {
        console.error('Fehler beim Senden der Bewertung:', error);
      }
    },
  generateUserId() {
      return 'uid_' + Math.random().toString(36).substr(2, 9);
    },
    getUserId() {
      let userId = localStorage.getItem('userId');
      if (!userId) {
        userId = this.generateUserId();
        localStorage.setItem('userId', userId);
      }
      return userId;
    },
  async fetchBausteine() {
      try {
        const response = await fetch('get_bausteine.php');
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        this.bausteine = await response.json();
      } catch (error) {
        console.error('Fehler beim Abrufen der Bausteine:', error);
      }
    },
  startSurvey() {
      this.showIntroduction = false;
    },
    handleRate(payload) {
      //const bausteinId = this.bausteine[this.currentIndex].id;
      //this.sendRating(bausteinId, payload.rating);
this.sendRating(parseInt(this.currentBaustein.id), payload.rating);
this.$refs.ratingButtons.resetSelection();     
this.advanceBlock();

    },
    skipBlock() {
      // Logik zum Überspringen der Bewertung
      this.advanceBlock();
    },
    advanceBlock() {
      if (this.currentIndex < this.bausteine.length - 1) {
        this.currentIndex++;
      } else {
        this.showTeacherInfoForm = true; // Zeigt das Formular an, wenn alle Bausteine bewertet wurden
      }
    }
  }
};
</script>

<style>
body {
  font-family: 'Nunito', sans-serif;
  text-align: left;
}
.skip-button {
  /* Stil für den Überspringen-Button */
  padding: 10px 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.skip-button:hover {
  background-color: #e9ecef;
}
header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.logo {
  height: 50px; /* oder eine andere Größe, je nach Bedarf */
}
#app {
  display: flex;
  justify-content: center; /* Zentriert den Inhalt horizontal */
  min-height: 100vh; /* Stellt sicher, dass die App den gesamten Viewport einnimmt */
}

.content-wrapper {
  width: 100%; /* Volle Breite auf kleinen Geräten */
  margin: 20px auto; /* Zentriert den Inhalt und fügt Abstand hinzu */
  text-align: left;
}

@media (min-width: 1024px) { /* Für größere Bildschirme wie Laptops/Desktops */
  .content-wrapper {
    width: 800px; /* Feste Breite auf größeren Bildschirmen */
  }
}

.start-button {
  padding: 15px 30px; /* Größerer Innenabstand */
  background-color: #007bff; /* Farbige Hintergrundfarbe */
  color: white; /* Weiße Textfarbe */
  border: none; /* Entfernen der Standard-Border */
  border-radius: 5px; /* Leicht abgerundete Ecken */
  font-size: 1.2em; /* Größere Schrift */
  cursor: pointer; /* Cursor-Stil ändern, um Klickbarkeit anzuzeigen */
  transition: background-color 0.3s; /* Weicher Übergang für Hover-Effekt */
}

.start-button:hover {
  background-color: #0056b3; /* Dunklere Farbe beim Darüberfahren */
}
</style>
