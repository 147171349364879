<template>
Dieses Thema ist für mich als (angehende) Lehrkraft:
 
  <div class="rating-buttons">
   <button
      v-for="(option, index) in options"
      :key="index"
      class="rating-button"
      @click="rate(index + 1)"
    >
      {{ option }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    currentBlockIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedRating: null
    };
  },
  methods: {
    rate(option) {
      this.selectedRating = option;
      this.$emit('rate', { rating: option, blockIndex: this.currentBlockIndex });
    },
resetSelection() {
    this.selectedRating = null;
  }
  },
  watch: {
currentBlockIndex() {
    this.resetSelection();
  },
    /*currentBlockIndex() {
      this.selectedRating = null;
    }*/
  }
};
</script>

<style>
.rating-buttons {
  display: flex;
  justify-content: left;
  margin: 20px 0;
}

.rating-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

@media (min-width: 768px) {
  .rating-button:hover {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
}
</style>
