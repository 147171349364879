<template>
  <div class="teacher-info-form">
   <form @submit.prevent="submitForm">
 <div class="additional-feedback">
 
      <h2>Zusätzliches Feedback</h2>

      <label for="unmentioned-topics">
        Folgende Themen sind nicht genannt, wären aber auch noch wichtig:
      </label>
      <textarea id="unmentioned-topics" v-model="additionalFeedback.unmentionedTopics"></textarea>

      <label for="important-aspects">
        Für ein solches kostenfreies Fortbildungsangebot zum Themenfeld "Digitale Medien in der inklusiven Schule wäre mir wichtig, dass:
      </label>
      <textarea id="important-aspects" v-model="additionalFeedback.importantAspects"></textarea>

      <label for="annoyances">
        Ärgerlich wäre es, wenn:
      </label>
      <textarea id="annoyances" v-model="additionalFeedback.annoyances"></textarea>
    </div>
	
   <h2>Angaben zu Ihnen</h2>
   
      <div>
      <label for="state">Bundesland:</label>
      <select id="state" v-model="teacherInfo.state">
        <option value="" disabled>Auswählen</option>
        <option v-for="state in states" :key="state" :value="state">
          {{ state }}
        </option>
      </select><br>
<!-- Dropdown für Länderauswahl, erscheint nur, wenn "außerhalb Deutschlands" ausgewählt ist -->
    <label for="country" v-if="teacherInfo.state === 'außerhalb Deutschlands'">Land:</label>
	<select v-if="teacherInfo.state === 'außerhalb Deutschlands'" v-model="selectedLand" id="country">
      <option value="">Bitte Land auswählen</option>
      <option value="Österreich">Österreich</option>
      <option value="Schweiz">Schweiz</option>
<option value="Luxemburg">Luxemburg</option>
<option value="Belgien">Belgien</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Italien">Italien</option>
      <!-- Weitere Länder ... -->
    </select>
    </div>
	
	
      <div class="school-types">
      <h3>(Angestrebte) Schulform:</h3>
	<div class="button-row">
      <button
        v-for="schoolType in schoolTypes"
        :key="schoolType"
        :class="{'selected': teacherInfo.schoolTypes.includes(schoolType)}"
        @click="toggleSchoolTypeSelection(schoolType)"
        class="toggle-button"
      >
        {{ schoolType }}
      </button></div>
    </div>
      <div class="education-phases">
      <h3>Phase der Lehrer:innenbildung:</h3>
	<div class="button-row2">
      <button
        v-for="phase in educationPhases"
        :key="phase"
        :class="{'selected': teacherInfo.educationPhases.includes(phase)}"
        @click="toggleSelection(phase)"
        class="toggle-button"
      >
        {{ phase }}
      </button>
    </div></div>
	<div class="checkbox-group">
	<h3>Informiert werden/mitmachen:</h3>
    <input type="checkbox" id="entwicklung" v-model="wantsToContribute">
    <label for="entwicklung">Ich möchte mich gerne bei der Bausteinentwicklung einbringen.</label>

    <input type="checkbox" id="testing" v-model="wantsToTest">
    <label for="testing">Ich möchte gerne Bausteine testen.</label>

    <input type="checkbox" id="newsletter" v-model="wantsNewsletter">
    <label for="newsletter">Ich möchte den Newsletter von inklusiv.digital abonnieren.</label>
  </div>

  <div v-if="wantsToContribute || wantsToTest || wantsNewsletter" class="email-container">
    <input type="email" v-model="email" placeholder="E-Mail-Adresse"> Ihre E-Mail-Adresse wird von Ihren Antworten getrennt verarbeitet.
  </div>
  


      <button type="submit" class="end-button">Fertig!</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
	additionalFeedback: {
        unmentionedTopics: '',
        importantAspects: '',
        annoyances: ''
      },
	wantsToContribute: false,
      wantsToTest: false,
      wantsNewsletter: false,
      email: '',
      teacherInfo: {
        state: '',
        schoolTypes: [],
        educationPhases: []
      },
  states: [
        "Baden-Württemberg",
        "Bayern",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hessen",
        "Mecklenburg-Vorpommern",
        "Niedersachsen",
        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Saarland",
        "Sachsen",
        "Sachsen-Anhalt",
        "Schleswig-Holstein",
        "Thüringen",
		"außerhalb Deutschlands"
      ],
educationPhases: [
        "Studierende:r",
        "Lehramtsanwärter:in",
        "Lehrkraft",
		"Uni/PH-Lehrende:r",
        "Seminarleiter:in",
        "Fortbildner:in"
      ],
schoolTypes: [
        "Grundschule",
		"Gesamtschule",
        "Gemeinschaftsschule",
        "Oberschule/Sekundarschule/Stadtteilschule",
		"Hauptschule",
		"Realschule",
		"Gymnasium",
        "Berufsschule",
        "Förderzentrum",
        "andere"
      ]
    };
  },
  methods: {toggleSelection(phase) {
      const index = this.teacherInfo.educationPhases.indexOf(phase);
      if (index === -1) {
        this.teacherInfo.educationPhases.push(phase);
      } else {
        this.teacherInfo.educationPhases.splice(index, 1);
      }
    },
toggleSchoolTypeSelection(schoolType) {
      const index = this.teacherInfo.schoolTypes.indexOf(schoolType);
      if (index === -1) {
        this.teacherInfo.schoolTypes.push(schoolType);
      } else {
        this.teacherInfo.schoolTypes.splice(index, 1);
      }
    },
    submitForm() {
      if (this.wantsToContribute || this.wantsToTest || this.wantsNewsletter) {
        if (!this.email) {
          alert('Bitte geben Sie eine E-Mail-Adresse ein.');
          return;
        }
		}
		}
  }
};
</script>

<style>
/* Fügen Sie hier Ihren CSS-Stil hinzu */
.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Abstand zwischen den Buttons */
}

.toggle-button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button.selected {
  background-color: #007bff;
  color: white;
}

.newsletter-signup {
  margin-top: 20px;
}

.newsletter-signup input {
  margin: 10px 0;
  width: 100%;
}
.checkbox-group {
  /* Styles für die Checkbox-Gruppe */
}

.checkbox-group input[type="checkbox"] {
  /* Styles für größere Checkboxen */
  transform: scale(1.5); /* Beispiel für eine größere Checkbox */
  margin-right: 10px;
}

.checkbox-group label {
  margin-right: 20px; /* Abstand zwischen den Checkboxen */
}
.end-button {
  padding: 15px 30px; /* Größerer Innenabstand */
  background-color: #007bff; /* Farbige Hintergrundfarbe */
  color: white; /* Weiße Textfarbe */
  border: none; /* Entfernen der Standard-Border */
  border-radius: 5px; /* Leicht abgerundete Ecken */
  font-size: 1.2em; /* Größere Schrift */
  cursor: pointer; /* Cursor-Stil ändern, um Klickbarkeit anzuzeigen */
  transition: background-color 0.3s; /* Weicher Übergang für Hover-Effekt */
   margin-top: 20px;
}

.end-button:hover {
  background-color: #0056b3; /* Dunklere Farbe beim Darüberfahren */
}
.button-row2 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button-row2 button:nth-child(3)::after {
  content: "";
  width: 100%;
}

.email-container {
  margin-top: 20px;
 /* Vergrößert den Abstand zum gesamten Container */
}

.additional-feedback textarea {
  width: 100%;
  height: 100px; /* Anpassbare Höhe */
  margin-bottom: 20px; /* Abstand zwischen den Textfeldern */
  padding: 10px; /* Innenabstand für bessere Lesbarkeit */
  border: 1px solid #ccc; /* Leicht sichtbare Grenze */
  border-radius: 5px; /* Abgerundete Ecken */
}


</style>
