<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: progressBarWidth }"></div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressBarWidth() {
      const progress = (this.current / this.total) * 100;
      return `${progress}%`;
    }
  }
};
</script>

<style>
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.progress-bar {
  height: 20px;
  background-color: #4caf50;
  width: 0; /* Initial width should be 0 */
  border-radius: 8px;
  transition: width 0.4s ease;
}
</style>
