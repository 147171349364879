<template>
  <div class="title-section">
    <div class="title-container">
      <h1>{{ title }}</h1>
    </div>
<!-- Toggle-Button -->
    <button @click="showBeschreibung = !showBeschreibung">
      {{ showBeschreibung ? 'Weniger anzeigen' : 'Was wird Gegenstand des Bausteins sein?' }}
    </button>

    <!-- Beschreibung -->
<div v-show="showBeschreibung" class="description" :class="{'description-active': showBeschreibung}">
    <p>{{ description }}</p>
     
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showBeschreibung: false,
     // Weitere Daten...
    };
  },
  watch: {
    title(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showBeschreibung = false;
      }
    },
    description(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showBeschreibung = false;
      }
    }
  },
computed: {
    beschreibungClass() {
      return {
        'beschreibung-active': this.showBeschreibung
      };
    }
}
}
</script>

<style>
.title-section {
  margin: 20px 0;
}

.title-section .title-container {
  height: 100px; /* Feste Höhe für den Titel */
  overflow: hidden; /* Versteckt überstehenden Text */
  text-overflow: ellipsis; /* Fügt "..." am Ende des überstehenden Textes ein */
}

.title-section .description-container {
  height: 150px; /* Feste Höhe für die Beschreibung */
  overflow-y: auto; /* Ermöglicht Scrollen innerhalb der Box, falls der Text länger ist */
}

.description {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
}

.description-active {
  max-height: 500px; /* Ein ausreichend großer Wert */
}

.title-section h1 {
  margin-bottom: 0.5em;
  color: #333;
}

.title-section p {
  margin-bottom: 1em;
  color: #666;
}


</style>
